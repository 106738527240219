import React, { useState, useEffect } from "react";
import "./EntranceTable.scss";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import Button from "../Button/Button";

const EntranceTable = ({ data, navigateBaseUrl, onSendEmail }) => {
  const navigate = useNavigate();
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isResponseOpen, setIsResponseOpen] = useState(false);

  const formatDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = date.toLocaleTimeString();
    return `${formattedDate} ${formattedTime}`;
  };

  const handleCheckboxChange = (email, isChecked) => {
    setSelectedEmails((prev) =>
      isChecked ? [...prev, email] : prev.filter((e) => e !== email)
    );
  };

  const handleSelectAll = (isChecked) => {
    setIsAllSelected(isChecked);
    setSelectedEmails(isChecked ? data.map((item) => item.email) : []);
  };

  const handleSendEmail = () => {
    if (selectedEmails.length === 0) {
      alert("Please select at least one recipient.");
      return;
    }
    setIsResponseOpen(true); // Open modal for email content
  };

  useEffect(() => {
    // Update all checkboxes if "Select All" is toggled.
    if (isAllSelected) {
      setSelectedEmails(data.map((item) => item.email));
    } else {
      setSelectedEmails([]);
    }
  }, [isAllSelected, data]);

  return (
    <div className="entrance-table">
      {/* Modal for email content */}
      <Modal isOpen={isResponseOpen} onClose={() => setIsResponseOpen(false)}>
        <EmailComp
          selectedEmails={selectedEmails}
          onSendEmail={onSendEmail}
          onClose={() => setIsResponseOpen(false)}
        />
      </Modal>
      <div className="entrance-header">
        {/* <span>Select Students to Email </span> */}
        <Button
          onClick={handleSendEmail}
          type={`primary`}
          text={`Send Email`}
        />
      </div>
      <div className="entrance-table-header">
        <input
          type="checkbox"
          className="select-all-checkbox"
          checked={isAllSelected}
          onChange={(e) => handleSelectAll(e.target.checked)}
        />
        <div className="header-id">Roll No</div>
        <div className="header-name">Name</div>
        <div className="header-whatsapp">Aadhar</div>
        <div className="header-class">Admission in Class</div>
        <div className="header-timestamp">TimeStamp</div>
      </div>
      {data.map((item) => (
        <div
          key={item.id}
          className={`entrance-row ${item.percent ? "entrance-row-green" : ""}`}
        >
          <input
            type="checkbox"
            className="row-checkbox"
            checked={selectedEmails.includes(item.email)}
            onChange={(e) => handleCheckboxChange(item.email, e.target.checked)}
          />
          <div
            className="entrance-row-content"
            onClick={() => navigate(`${navigateBaseUrl}/${item.roll_no}`)}
          >
            <div className="row-id">{item.roll_no}</div>
            <div className="row-name">
              {`${item.first_name} ${item.middle_name} ${item.last_name}`}
            </div>
            <div className="row-whatsapp">{item.aadhar}</div>
            <div className="row-class">{item.adm_class}</div>
            <div className="row-timestamp">
              {formatDateAndTime(item.timestamp)}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EntranceTable;

function EmailComp({ selectedEmails, onSendEmail, onClose }) {
  const [subject, setSubject] = useState("Important Information");
  const [body, setBody] = useState("");

  const handleSend = () => {
    if (!subject.trim() || !body.trim()) {
      alert("Subject and body cannot be empty.");
      return;
    }

    // Pass the email content and selected emails to the parent component
    onSendEmail({ emails: selectedEmails, subject, body });
    onClose(); // Close the modal after sending
  };

  return (
    <div className="emailcomp">
      <h2>Send Email</h2>
      <p>{`Sending email to ${selectedEmails.length} recipients`}</p>
      {/* <div className="email-field">
        <label>Subject:</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          placeholder="Enter email subject"
        />
      </div> */}
      <div className="email-field">
        <label>Body:</label>
        <textarea
          value={body}
          onChange={(e) => setBody(e.target.value)}
          placeholder="Enter email content"
        />
      </div>
      <div className="email-actions">
        <button onClick={handleSend}>Send</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
}
