import React, { useState, useEffect } from "react";
import axios from "axios";
import "./StudentFee.scss";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";
import Modal from "../Modal/Modal";
import ResponseModal from "../ResponseModal/ResponseModal";

// const BE_URL = "http://localhost:8000";
const BE_URL = "https://server.classerp.in";

const StudentFee = ({
  admin = false,
  handleRefresh,
  adminStudentDetails = {},
  adminInstallments = [],
  cancelled,
}) => {
  const [details, setDetails] = useState({
    studentDetails: {},
    installments: [],
  });

  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentInstallmentId, setpaymentInstallmentId] = useState(null);
  const [paymentAmount, setpaymentAmount] = useState(null);
  const [paymentName, setpaymentName] = useState(null);
  const [receiptNumber, setReceiptNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPaymentOpen, setisPaymentOpen] = useState(false);
  const [error, setError] = useState("");

  const [msg, setmsg] = useState(null);
  const [responseType, setresponseType] = useState(null);
  const [isResponseOpen, setisResponseOpen] = useState(false);

  const { additial_amount } = adminStudentDetails;
  console.log(additial_amount);

  useEffect(() => {
    if (!admin) {
      fetchDetails();
    }
  }, []);

  const fetchDetails = async () => {
    setIsLoading(true);
    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.get(`${BE_URL}/api/general/studentfee`, {
        headers: { Authorization: `Bearer ${tokens.accessToken}` },
      });
      setDetails(response.data);
      setIsLoading(false);
    } catch (err) {
      setError("Error fetching data. Please try again later.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(adminStudentDetails);
    if (admin) {
      setDetails({
        studentDetails: adminStudentDetails,
        installments: adminInstallments,
      });
    }
  }, [admin, adminStudentDetails, adminInstallments]);

  const toggleInstallmentDetails = (index) => {
    setDetails((prevState) => ({
      ...prevState,
      installments: prevState.installments.map((inst, i) => {
        if (i === index) {
          return { ...inst, isOpen: !inst.isOpen };
        }
        return inst;
      }),
    }));
  };

  const handlePayment = async (installment) => {
    setIsLoading(true);
    try {
      console.log("student details : ", details.studentDetails);
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));

      // Step 1: Initiate payment on the backend (create order)
      const response = await axios.post(
        `${BE_URL}/api/payment/initiate`,
        {
          installmentId: installment.id,
          roll_no: details.studentDetails.roll_no,
          amount: installment.final_fee,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      setIsLoading(false);

      if (response.data.status === "success") {
        // Step 2: Initialize Razorpay Checkout (frontend modal)
        const { order_id, amount, currency, studentDetails } = response.data;

        console.log(response.data);

        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Add your Razorpay API key here
          amount: amount, // Amount in paise
          currency: currency,
          name: "Krishna Chaitanya Educare Pvt. Ltd.",
          description: "Fee Payment",
          image:
            "https://classerp.in/static/media/logo.6c36cc13ed9ba2894757.png", // Optional: Your company logo
          order_id: order_id, // Order ID returned from your backend
          handler: async function (paymentResult) {
            const verifyResponse = await axios.post(
              `${BE_URL}/api/payment/verify`,
              {
                razorpay_order_id: paymentResult.razorpay_order_id,
                razorpay_payment_id: paymentResult.razorpay_payment_id,
                razorpay_signature: paymentResult.razorpay_signature,
                amount: amount, // Amount in paise
              }
            );

            if (verifyResponse.data.status === "success") {
              // Update the status to "Processing" after verification (temporary status)
              const statusUpdateResponse = await axios.post(
                `${BE_URL}/api/payment/update-status`,
                {
                  order_id: paymentResult.razorpay_order_id,
                  status: "Processing", // Set status to "Processing"
                }
              );

              if (statusUpdateResponse.data.status === "success") {
                window.scrollTo(0, 0);
                setresponseType("Success");
                setmsg(
                  `Payment successful for amount Rs ${
                    amount / 100
                  }. Your order id is ${order_id}.`
                );
                setisResponseOpen(true);
                fetchDetails();
              } else {
                alert("Payment processing failed. ");
              }
            } else {
              alert(
                "Payment verification failed. Any amount, if deducted, will be refunded in 5 working days."
              );
            }
          },
          prefill: {
            name: studentDetails.name,
            email: studentDetails.email,
            contact: studentDetails.phone,
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp = new window.Razorpay(options);
        rzp.open(); // Open Razorpay Checkout modal
      } else {
        console.error("Failed to initiate payment:", response.data);
        alert("Failed to initiate payment. Please try again.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error initiating payment:", error);
      alert("Error initiating payment. Please try again.");
    }
  };

  const checkPrevious = (index) => {
    if (index === 1) return true;

    console.log(details.installments);

    const prevInst = details.installments.find(
      (inst) => inst.installment_number === index - 1
    );
    if (prevInst.status === "Paid") return true;
    else return false;
  };

  const adminPayment = (id, amount, name) => {
    window.scrollTo(0, 0);
    setpaymentInstallmentId(id);
    setpaymentAmount(amount);
    setpaymentName(name);
    setisPaymentOpen(true);
  };

  // if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="stfeedetails">
      <Modal isOpen={isPaymentOpen} onClose={() => setisPaymentOpen(false)}>
        <AcceptPayment
          installmentId={paymentInstallmentId}
          onClose={() => setisPaymentOpen(false)}
          handleRefresh={handleRefresh}
          amount={paymentAmount}
          name={paymentName}
        />
      </Modal>
      <Modal isOpen={isResponseOpen} onClose={() => setisResponseOpen(false)}>
        <ResponseModal
          type={responseType}
          msg={msg}
          onClick={() => setisResponseOpen(false)}
        />
      </Modal>
      {isLoading && <Loader />}
      <div className="stfeedetails-meta">
        <div className="stfeedetails-meta-title">Fee Summary</div>
        <div className="stfeedetails-meta-content">
          <div>Total Fee: &#8377; {details.studentDetails.total_fee}</div>
          <div>
            Scholarship Amount: &#8377;{" "}
            {details.studentDetails.scholarship_amount || 0}
          </div>
          <div>
            Additional Discount: &#8377;{" "}
            {details.studentDetails.additial_amount || 0}
          </div>
          <div>Final Fee: &#8377; {details.studentDetails.final_fee}</div>
        </div>
      </div>

      <div className="installments-section">
        <h3>Installments</h3>
        {details.installments.map((installment, index) => (
          <>
            {installment.is_active && (
              <div key={installment.id} className="installment">
                <div
                  className="installment-header"
                  onClick={() => toggleInstallmentDetails(index)}
                >
                  <div className="installment-header-info">
                    <div className="installment-header-name">
                      {installment.installment_name}
                    </div>
                    <div
                      className={`${
                        installment.status === "Paid"
                          ? "installment-header-info-paid"
                          : "installment-header-info-unpaid"
                      }`}
                    >
                      {installment.status}
                    </div>
                  </div>
                  <span>{installment.isOpen ? "-" : "+"}</span>
                </div>
                {installment.isOpen && (
                  <div className="installment-details">
                    <div className="detail">
                      <p className="title">Due Date</p>
                      <p>
                        {new Date(installment.due_date).toLocaleDateString()}
                      </p>
                    </div>
                    <div className="detail">
                      <p className="title">Amount</p>
                      <p>{installment.amount}</p>
                    </div>
                    {installment.discount_amount ? (
                      <div className="detail">
                        <p className="title">Discount Name</p>
                        <p>{installment.discount_name || "N/A"}</p>
                      </div>
                    ) : (
                      ""
                    )}
                    {installment.discount_amount ? (
                      <div className="detail">
                        <p className="title">Discount Amount</p>
                        <p>{installment.discount_amount || 0}</p>
                      </div>
                    ) : (
                      ""
                    )}
                    {/* {installment.discount_amount ? (
                      <div className="detail">
                        <p className="title">Final Fee</p>
                        <p>{installment.final_fee}</p>
                      </div>
                    ) : (
                      ""
                    )} */}
                    <div className="detail">
                      <p className="title">Late Fees</p>
                      <p
                        className={`${
                          installment.late_fee > 0 ? "latefee" : ""
                        }`}
                      >
                        {installment.late_fee}
                      </p>
                    </div>
                    <div className="detail">
                      <strong>
                        <p className="title">Final Fees</p>
                      </strong>
                      <strong>
                        <p>{installment.final_fee}</p>
                      </strong>
                    </div>
                    {installment.status === "UnPaid" &&
                      !admin &&
                      checkPrevious(installment.installment_number) && (
                        <Button
                          onClick={() => handlePayment(installment)}
                          text={`Pay Now`}
                        />
                      )}
                    {installment.status === "UnPaid" &&
                      admin &&
                      !cancelled &&
                      checkPrevious(installment.installment_number) && (
                        <Button
                          onClick={() =>
                            adminPayment(
                              installment.id,
                              installment.final_fee,
                              installment.installment_name
                            )
                          }
                          text={`Accept Payment`}
                        />
                      )}
                    {installment.status === "Paid" && (
                      <a target="_black" href={installment.receipt_url}>
                        Receipt
                      </a>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default StudentFee;

const AcceptPayment = ({
  installmentId,
  amount,
  name,
  onClose,
  handleRefresh,
}) => {
  const [paymentMethod, setPaymentMethod] = useState("");
  const [receiptNumber, setReceiptNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const handlePaymentAcceptance = async () => {
    if (!paymentMethod || !receiptNumber) {
      alert("Please fill in all fields.");
      return;
    }

    setIsLoading(true);

    try {
      const tokens = JSON.parse(localStorage.getItem("ERPTokens"));
      const response = await axios.post(
        `${BE_URL}/api/acceptPayment`,
        {
          installmentId,
          paymentMethod,
          transaction_no: receiptNumber,
        },
        {
          headers: { Authorization: `Bearer ${tokens.accessToken}` },
        }
      );

      if (response.data.status === "success") {
        alert("Payment accepted successfully.");
        handleRefresh();
        onClose(); // Close modal on success
      } else {
        setError("Failed to accept payment.");
      }
    } catch (error) {
      console.error("Error accepting payment:", error);
      alert("Error accepting payment. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="accept-payment">
      <h2>Accept Payment</h2>
      <div className="info">
        {name} - &#8377; {amount}{" "}
      </div>
      <div className="form-group">
        <label htmlFor="payment-method">Payment Method</label>
        <select
          id="payment-method"
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        >
          <option value="">Select Method</option>
          <option value="Cash">Cash</option>
          <option value="Demand Draft">Demand Draft (DD)</option>
          <option value="Bank Transfer">Bank Transfer</option>
        </select>
      </div>
      <div className="form-group">
        <label htmlFor="receipt-number">Transaction Number</label>
        <input
          type="text"
          id="receipt-number"
          value={receiptNumber}
          onChange={(e) => setReceiptNumber(e.target.value)}
        />
      </div>
      <div className="action-buttons">
        <button onClick={handlePaymentAcceptance} disabled={isLoading}>
          {isLoading ? "Processing..." : "Accept Payment"}
        </button>
      </div>
    </div>
  );
};
